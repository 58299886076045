code
{
    word-break: break-all;
}

pre
{
    width: 0;
    padding: 1rem;
    min-width: 100%;
    margin: 0 0 1.5rem;
    overflow: auto;
    background-color: #222 !important;
    border: 1px solid rgba(#fff, 0.2);
    color: #fff;
}

div.highlight
{
    margin: 0 0 1.5rem;
}