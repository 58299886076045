@use "sass:meta";
@use "parts/base";
@use "parts/page";

header :first-child,
article :first-child,
aside :first-child
{
    margin-top: 0;
}

header :last-child,
article :last-child
{
    margin-bottom: 0;
}

@include meta.load-css("parts/header");
@include meta.load-css("parts/content");
@include meta.load-css("parts/sidebar");
@include meta.load-css("parts/grid");
@include meta.load-css("parts/code");
@include meta.load-css("parts/gallery");
@include meta.load-css("parts/pagination");