.grid
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 2rem;

    > figure
    {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        a, img
        {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
