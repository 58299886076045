html
{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 1.667;
    color: #333;
}

body
{
    background-image: url('../img/aurora.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding: 2rem 0;
    margin: 0;

    &.animated
    {
        background-image: url('../img/aurora-anim.svg');
    }

    @media (prefers-color-scheme: dark)
    {
        background-color: #222;
        color: #fff;
    }
}

*
{
    box-sizing: border-box;
}

a
{
    color: currentColor;
    text-decoration: none;
}

p
{
    margin: 0 0 1.5em;
}

h1, h2, h3
{
    margin: 1.5em 0 0.25em;
    font-weight: normal;
    letter-spacing: -0.0125rem;
    line-height: 1.2;
}

h1
{
    font-size: 2.4rem;

    @media (max-width: 36rem)
    {
        font-size: 2.1rem;
    }
}

h2, h3
{
    font-size: 1.6667rem;

    @media (max-width: 36rem)
    {
        font-size: 1.5rem;
    }
}

figure
{
    text-align: center;
    margin: 0 0 1rem;

    @media (prefers-color-scheme: dark)
    {
        &.invert-dark img
        {
            filter: invert(1) contrast(0.6) hue-rotate(180deg) saturate(2);
        }
    }
}

img
{
    max-width: 100%;
}

i.right-caret
{
    font-style: normal;
    font-size: .75em;
    vertical-align: .1em;

    &::before
    {
        content: "⏵";
    }
}
