div.main
{
    grid-area: b;
    padding: 0;
    display: grid;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    gap: 2rem;
    min-width: 0;

    @media (min-width: 36.001rem) and (max-width: 72rem)
    {
        padding: 0 1rem;
    }
}

article
{
    padding: 2rem;
    background-color: #fff;
    border-radius: .25rem;
    border: .0625rem solid rgba(0,0,0,0.667);
    word-break: break-word;

    @media (max-width: 36rem)
    {
        text-align: left;
    }

    @media (prefers-color-scheme: dark)
    {
        background-color: #333;
    }

    strong
    {
        font-weight: 700;
    }

    img
    {
        display: block;
        margin: 0 auto;
    }

    a
    {
        text-decoration: underline;
    }

    h2 > a
    {
        text-decoration: none;
    }

    > div
    {
        margin: 0 0 1.5rem;
    }
}