aside.pagination-wrapper
{
    text-align: center;
}

ul.pagination
{
    display: inline-grid;
    list-style-type: none;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}