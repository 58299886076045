main
{
    width: 72rem;
    max-width: 92vw;
    margin: 2rem auto;
    background-color: rgba(255,255,255,0.667);
    backdrop-filter: saturate(2) brightness(0.7) blur(10px);
    border-radius: .25rem;
    border: .0625rem solid rgba(0,0,0,0.667);
    box-shadow: 0 0 2rem -0.5rem rgba(0,0,0,.667);


    padding: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-areas:
        "a a"
        "b c";
    grid-template-columns: 3fr 1fr;

    @media (max-width: 72rem)
    {
        padding: 1rem 0;
        gap: 1rem;
        grid-template-areas: "a" "b" "c";
        grid-template-columns: 100%;
    }

    @media (prefers-color-scheme: dark)
    {
        background-color: rgba(255,255,255,0.25);
    }

    > *
    {
        padding: 2rem;
    }
}