div.side
{
    padding: 0;
    display: grid;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    gap: 2rem;

    @media (max-width: 72rem)
    {
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        gap: 1rem;
        padding: 0 1rem;
    }

    @media (max-width: 36rem)
    {
        grid-auto-flow: row;
        padding: 0;
    }
}


aside
{
    padding: 1.5rem;
    background-color: rgba(white, 0.667);
    border-radius: .25rem;
    border: .0625rem solid rgba(black, 0.667);

    @media (prefers-color-scheme: dark)
    {
        background-color: #333;
    }

    > h3
    {
        font-size: 1.5rem;
        margin-bottom: .333rem;
        font-weight: 500;
    }

    > nav
    {
        display: flex;
        flex-direction: column;

        > a
        {
            display: block;
            padding: .25rem 0;
            color: #333;
            text-decoration: none;

            @media (prefers-color-scheme: dark)
            {
                color: #fff;
            }
        }
    }
}