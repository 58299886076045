header
{
    grid-area: a;
    padding: 0;

    @media (max-width: 72rem)
    {
        padding: 0 2rem;
    }

    @media (prefers-color-scheme: dark)
    {
        text-shadow: 0 0 .333rem #222;
    }

    > hgroup > *
    {
        margin: 0 0 0.25rem;
    }
}

