.gallery
{
    display: flex;
    gap: 1.5rem;
    justify-content: center;

    img
    {
        width: 15rem;
    }
}